import React from 'react';
import './App.css';

import {BrowserRouter, Route, Routes} from "react-router-dom";
import Privacy from "./Privacy";
import Invis from "./proj/Invis";

function App() {

    return (
        <BrowserRouter>
           <Routes>
               {/*<Route path="/" element={Invis()}/>*/}
               {/*<Route path="/privacy" element={Privacy({*/}
               <Route path="/" element={Privacy({
                   en: 'Invis',
                   zh: 'Invis',
                   email: 'suggestpaqe@163.com',
               })}/>
               <Route path="/global" element={Privacy({
                   en: 'Global Store Go',
                   zh: 'Global Store Go',
                   email: 'suggestpaqe@163.com',
               })}/>
           </Routes>
        </BrowserRouter>
    );
}

export default App;
